@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* 
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.navBar {
  background-color: #464951;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(10px + 3vmin);
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

a {
  color: white;
  text-decoration: none;
}

.logo {
  height: calc(50px + 1vmin);
  width: calc(50px + 1vmin);
}

.nav-logo {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.navLinks {
  font-size: calc(10px + 1vmin);
  display: flex;
  gap: 30px;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

input {
  background-color: #464951;
  text-align: center;
  width: 300px;
  height: 50px;
  margin: 10px;
  font-size: calc(10px + 2vmin);
  color: white;
  border: 0px;
  box-shadow: inset 0 0 10px #ff9914;
  -webkit-transition: 200ms ease-out;
  -moz-transition: 200ms ease-out;
  -o-transition: 200ms ease-out;
  transition: 200ms ease-out;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader::after {
  content: "";
  width: 100px;
  height: 100px;
  border: 13px solid #ff9914;
  border-top-color: #f21b3f;
  border-radius: 50%;
  animation: loading 1s ease infinite;
}

@keyframes loading {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

button {
  background-color: #464951;
  margin: 10px;
  font-size: calc(10px + 2vmin);
  color: white;
  border: 0px;
  box-shadow: 12px 12px 12px #212429, -10px -10px 10px #30353e;
  -webkit-transition: 200ms ease-out;
  -moz-transition: 200ms ease-out;
  -o-transition: 200ms ease-out;
  transition: 200ms ease-out;
}

button.start {
  width: 300px;
  height: 50px;
}

.players-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: min(200px, 100vw);
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.square.settings {
  aspect-ratio: 1/1;
  margin: 20px;
}

.popup {
  position: fixed;
  inset: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
  width: 480px;
  max-width: 640px;
  background-color: #fff;
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.number {
  font-size: 3rem;
}

.number::after {
  clear: both;
  content: "";
  display: table;
}

button.number {
  inset: 0;
  font-size: 5rem;
  z-index: 10;
  font: bold;
  color: rgba(0, 0, 0, 0.469);
}

.info {
  margin: 10px;
}

.selected-size {
  background: rgb(229, 187, 123);
  border: 4px;
}

.finished .loser {
  opacity: 0.5;
}

.finished .winner {
  border: 12px solid;
}

.square {
  position: relative;
  width: 30%;
  aspect-ratio: 1/1;
}

.small-square {
  position: relative;
  flex: 1;
  width: 10%;
  aspect-ratio: 1/1;
}


.board-row {
  width: min(500px, 100vw);
  display: flex;
  justify-content: space-between;
}

.board {
  display: flex;
  justify-content: center;
}

.circle {
  border: 10px solid;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 2px #0e0f10, 2px 2px 2px #0e0f10 inset;
}

.circle.large {
  width: 80%;
  aspect-ratio: 1/1;
}

.circle.medium {
  width: 50%;
  aspect-ratio: 1/1;
}

.circle.small {
  width: 20%;
  aspect-ratio: 1/1;
}

.circle.red {
  border-color: #f21b3f;
}

.circle.green {
  border-color: #29bf12;
}

.circle.blue {
  border-color: #08bdbd;
}

.circle.purple {
  border-color: #ff9914;
} */
